import { CONFIG } from "./config"
import {
  StatusRequest,
  StatusApiParams,
  StatusApiResponse,
  StatusResult,
} from "./types"
import { WidoError } from "./WidoError"

/**
 * Get the transaction status alongside the transaction hash for the destination chain.
 */
export async function getStatus(request: StatusRequest): Promise<StatusResult> {
  const { txHash, chainId } = request

  const endpoint = `status`

  const paramsObj: StatusApiParams = {
    chain_id: String(chainId),
    tx_hash: txHash,
  }
  const params = new URLSearchParams(paramsObj)

  const url = `${CONFIG.WIDO_API_URL}/${endpoint}?${params}`
  const res = await fetch(url)

  if (!res.ok) {
    throw WidoError.from_api_response(await res.json())
  }

  const data: StatusApiResponse = await res.json()

  return {
    status: data.status,
    toChainId: data.to_chain_id,
    toTxHash: data.to_tx_hash,
  }
}
