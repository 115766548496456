import { CONFIG } from "./config"
import { Web3Address, ChainId, WidoContractApiResponse } from "./types"
import { WidoError } from "./WidoError"

/**
 * Get the address of the Wido Token Manager contract. Before zapping, the end user needs to approve this contract, allowing it to spend their ERC-20 token.
 * @deprecated in favor of `getWidoSpender`. This function will be removed in the future, please migrate.
 */
export async function getWidoContractAddress(
  chainId: ChainId
): Promise<Web3Address> {
  const endpoint = `contract_address`

  const params = new URLSearchParams({
    chain_id: String(chainId),
  })

  const url = `${CONFIG.WIDO_API_URL}/${endpoint}?${params}`
  const res = await fetch(url)

  if (!res.ok) {
    throw WidoError.from_api_response(await res.json())
  }

  const data: WidoContractApiResponse = await res.json()

  return data.spender as Web3Address
}
