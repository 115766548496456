import { CONFIG } from "./config"
import {
  SupportedTokensApiParams,
  SupportedTokensRequest,
  Token,
} from "./types"
import { WidoError } from "./WidoError"

/**
 * @internal
 */
export async function getSupportedTokensInternal(
  request?: SupportedTokensRequest & {
    /**
     * Whether to include the tokens from the preview list.
     *
     * @default false
     */
    includePreview?: boolean
  }
) {
  const {
    chainId = [],
    protocol = [],
    includePricing = false,
    includePreview = false,
    toChainId,
    toToken,
  } = request || {}

  const endpoint = `tokens`

  const paramsObj: SupportedTokensApiParams = {
    include_pricing: String(includePricing),
    include_preview: String(includePreview),
  }
  if (toChainId) {
    paramsObj.to_chain_id = String(toChainId)
  }
  if (toToken) {
    paramsObj.to_token = toToken
  }
  const params = new URLSearchParams(paramsObj)
  chainId.forEach((chainId) => params.append("chain_id", String(chainId)))
  protocol.forEach((protocol) => params.append("protocol", protocol))

  const url = `${CONFIG.WIDO_API_URL}/${endpoint}?${params}`
  const res = await fetch(url)

  if (!res.ok) {
    throw WidoError.from_api_response(await res.json())
  }

  return (await res.json()).tokens as Token[]
}
