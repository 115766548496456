import { ConfigType } from "./types"

/**
 * @internal
 */
export const ZERO_ADDRESS = "0x0000000000000000000000000000000000000000"

/**
 * @internal
 */
export const CONFIG: ConfigType = {
  WIDO_API_URL: "https://api.joinwido.com",
  SEGMENT_KEY: "G4ttHjzxUXZ2ORLGHpXE8yEfCCkqjPXx",
  DEBUG: process.env.NODE_ENV === "development",
}

/**
 * @internal
 */
export const useLocalApi = () => {
  // eslint-disable-next-line no-console
  console.log("Using local Wido API URL...")
  CONFIG.WIDO_API_URL = "http://127.0.0.1:8080"
  CONFIG.SEGMENT_KEY = "qu7qvYAa1ziyWWkgsInDw4K2YQDFxWOR"
}

/**
 * @internal
 */
export const useProdApi = () => {
  CONFIG.WIDO_API_URL = "https://api.joinwido.com"
  CONFIG.SEGMENT_KEY = "G4ttHjzxUXZ2ORLGHpXE8yEfCCkqjPXx"
}
