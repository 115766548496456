import { CONFIG } from "./config"
import {
  Web3Address,
  WidoContractApiResponse,
  WidoSpenderRequest,
  ContractAddressApiParams,
} from "./types"
import { WidoError } from "./WidoError"

/**
 * Get contract address that the user needs to approve before zapping.
 * As per the ERC20 standard, Wido needs permission to spend tokens on behalf of `user`.
 */
export async function getWidoSpender(
  request: WidoSpenderRequest
): Promise<Web3Address> {
  const { chainId, fromToken, toChainId, toToken } = request
  const endpoint = `contract_address`

  const paramsObj: ContractAddressApiParams = {
    chain_id: String(chainId),
    from_token: fromToken,
    to_chain_id: String(toChainId),
    to_token: toToken,
  }
  const params = new URLSearchParams(paramsObj)

  const url = `${CONFIG.WIDO_API_URL}/${endpoint}?${params}`
  const res = await fetch(url)

  if (!res.ok) {
    throw WidoError.from_api_response(await res.json())
  }

  const data: WidoContractApiResponse = await res.json()

  return data.spender as Web3Address
}
