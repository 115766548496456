import { ApproveApiParams, ApproveRequest, ApproveResult } from "./types"
import { CONFIG, MAX_UINT256 } from "./wido"
import { WidoError } from "./WidoError"

/**
 * Get an easy-to-consume unsigned transaction for changing the allowance amount,
 * e.g. how many tokens can Wido spend on behalf of `user`.
 *
 * Call this method with `chainId`, `fromToken` and `toToken` before zapping!
 */
export async function approve(request: ApproveRequest): Promise<ApproveResult> {
  const {
    tokenAddress,
    fromToken,
    toToken,
    chainId,
    fromChainId,
    toChainId,
    amount = MAX_UINT256,
    tokenId,
  } = request

  const endpoint = `approve`

  if (!chainId && !fromChainId) {
    throw new Error(`fromChainId must be set`)
  }

  const paramsObj: ApproveApiParams = {
    chain_id: fromChainId ? String(fromChainId) : String(chainId),
    amount: amount,
    from_token: fromToken,
    to_token: toToken,
  }
  if (tokenAddress) {
    paramsObj.token_address = tokenAddress
  }
  if (tokenId) {
    paramsObj.token_id = tokenId
  }
  if (toChainId) {
    paramsObj.to_chain_id = String(toChainId)
  }

  const params = new URLSearchParams(paramsObj)

  const url = `${CONFIG.WIDO_API_URL}/${endpoint}?${params}`
  const res = await fetch(url)

  if (!res.ok) {
    throw WidoError.from_api_response(await res.json())
  }

  const data: ApproveResult = await res.json()

  return {
    to: data.to,
    data: data.data,
  }
}
