import { ErrorApiResponse, ErrorCode, ValidationError } from "./types"

export class WidoError extends Error {
  name = "WidoError"
  code: ErrorCode
  detail: string
  validationErrors?: ValidationError[]

  constructor(
    code: ErrorCode,
    detail: string,
    validationErrors?: ValidationError[]
  ) {
    let message

    switch (code) {
      case "INVALID_REQUEST":
        message = `${detail}: ${validationErrors
          ?.map((err) => err.msg)
          .join(", ")}`
        break

      default:
        message = `${code}: ${detail}`
        break
    }

    super(message)

    this.detail = detail
    this.code = code
    this.validationErrors = validationErrors
  }

  static from_api_response(response: ErrorApiResponse) {
    return new WidoError(
      response.code,
      response.detail,
      response.validationErrors
    )
  }
}
