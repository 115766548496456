import { ChainId, ChainName } from "../types"

/**
 * @internal
 */
export const MAX_UINT256 =
  "115792089237316195423570985008687907853269984665640564039457584007913129639935"

const chainIdMap: Record<ChainName, ChainId> = {
  other: 1,
  mainnet: 1,
  goerli: 5,
  fantom: 250,
  arbitrum: 42161,
  avalanche: 43114,
  moonriver: 1285,
  polygon: 137,
  celo: 42220,
  phuture: 1338,
  optimism: 10,
  binance: 56,
  aurora: 1313161554
}

/**
 * @internal
 */
export function getChainId(chainName: ChainName): ChainId {
  if (!chainIdMap[chainName]) {
    throw new Error(`InputError: chain with name ${chainName} not found.`)
  }

  return chainIdMap[chainName]
}

const chainNameMap: Record<ChainId, ChainName> = {
  1: "mainnet",
  5: "goerli",
  250: "fantom",
  43114: "avalanche",
  42161: "arbitrum",
  1285: "moonriver",
  137: "polygon",
  42220: "celo",
  1338: "phuture",
  10: "optimism",
  56: "binance",
  1313161554: "aurora"
}

/**
 * @internal
 */
export function getChainName(chainId: ChainId): ChainName {
  if (!chainNameMap[chainId]) {
    throw new Error(`InputError: chain with id ${chainId} not found.`)
  }

  return chainNameMap[chainId]
}
