import { CONFIG } from "./config"
import { Web3Address, ChainId, Balance } from "./types"
import { WidoError } from "./WidoError"

/**
 * Query the Wido API and return a list of ERC-20 tokens found in the user's wallet,
 * that can be used for routing.
 */
export async function getBalances(user: Web3Address, chainId?: ChainId[]) {
  const endpoint = `balances`

  const params = new URLSearchParams({
    user: user,
  })
  chainId?.forEach((chainId) => params.append("chain_id", String(chainId)))

  const url = `${CONFIG.WIDO_API_URL}/${endpoint}?${params}`
  const res = await fetch(url)

  if (!res.ok) {
    throw WidoError.from_api_response(await res.json())
  }

  return (await res.json()).balances as Balance[]
}
